<template>
  <v-app>
    <v-layout id="main" v-if="renderPage === 'main'">
      <v-app-bar
        app
        color="teal"
        dark
      >
        <v-app-bar-nav-icon @click.stop="navDrawer = !navDrawer" class="hidden-lg-and-up"/>
        <h2 class="ml-5">{{appBarText}}</h2>
        <v-spacer/>
        <v-btn color="error" dark class="mr-2 ml-3" @click="logout"><v-icon class="mr-2">exit_to_app</v-icon>Logout</v-btn>
      </v-app-bar>
      <v-navigation-drawer
        app
        v-model="navDrawer"
      >                
        <v-toolbar 
          dark
        >
          <v-toolbar-title>Schedule Portal</v-toolbar-title>
        </v-toolbar>
        <v-list-item-group v-model="menuActiveElement" mandatory>
          <v-list-item v-for="item in menu_items" :key="item.title" :value="item.id" v-show="item.show" @click="updateAppBar(item.title)">
              <v-list-item-action>
                  <v-icon color="black">{{item.icon}}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                  <v-list-item-title>{{item.title}}</v-list-item-title>
              </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-navigation-drawer>
      <v-main app>
        <!-- Provides the application the proper gutter -->
        <v-container fluid>
          <events v-if="menuActiveElement === 'events'"/>
          <upload-schedule v-if="menuActiveElement === 'uploadSchedule'"/>
          <subscribers v-if="menuActiveElement === 'subscribers'"/>
          <admins v-if="menuActiveElement === 'admins'"/>
          <settings v-if="menuActiveElement === 'settings'"/>
          <sports v-if="menuActiveElement === 'sports'"/>
        </v-container>
      </v-main>
      <v-footer 
        app
      >
        <span>&copy;Schedule Portal 2020</span>
      </v-footer>
    </v-layout>
    <v-layout id="login" v-if="renderPage === 'login'" @keydown.enter="submitInfos">
      <v-main>
        <v-container
          class="fill-height"
          fluid
        >
          <v-row
            align="center"
            justify="center"
          >
            <v-col
              cols="12"
              sm="8"
              md="4"
            >
              <v-card class="elevation-12">
                <v-toolbar 
                  color="teal"
                  dark
                >
                  <v-toolbar-title>Schedule Portal Login</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-form ref="form">
                    <v-text-field
                      label="Username"
                      ref="username"
                      v-model="username"
                      prepend-icon="person"
                      type="text"
                      :rules="rules.username"
                    />
                    <v-text-field
                      id="password"
                      label="Password"
                      ref="password"
                      v-model="password"
                      prepend-icon="lock"    
                      :append-icon="hidePassword ? 'visibility' : 'visibility_off'"
                      @click:append="() => (hidePassword = !hidePassword)"
                      :type="hidePassword ? 'password' : 'text'"
                      :rules="rules.password"
                    />
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="primary" @click="submitInfos" :disabled="formIsValid"><v-icon class="mr-1">login</v-icon>Login</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>

          <v-snackbar v-model="snackbar.show" :color="snackbar.color">
              {{ snackbar.text }}

              <template v-slot:action="{ attrs }">
                  <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="snackbar.show = false"
                  >
                    <v-icon>clear</v-icon>
                  </v-btn>
              </template>
          </v-snackbar>
        </v-container>
      </v-main>
    </v-layout>
  </v-app>
</template>

<script>
import axios from 'axios'
import Events from './components/Events'
import UploadSchedule from './components/UploadSchedule'
import Subscribers from './components/Subscribers'
import Admins from './components/Admins'
import Settings from './components/Settings'
import Sports from './components/Sports'

export default {
  name: 'App',
  components: {
    events: Events,
    'upload-schedule': UploadSchedule,
    subscribers: Subscribers,
    admins: Admins,
    settings: Settings,
    sports: Sports,
  },
  data: () => ({
    appBarText: '',
    navDrawer: undefined,
    menu_items: [
        {
            title: 'Events',
            id: 'events',
            icon: 'emoji_events',
            show: true
        },
        {
            title: 'Upload Schedule',
            id: 'uploadSchedule',
            icon: 'update',
            show: true
        },
        {
            title: 'Subscribers',
            id: 'subscribers',
            icon: 'assignment',
            show: true
        },
        {
            title: 'Admins',
            id: 'admins',
            icon: 'person',
            show: true
        },
        {
            title: 'Settings',
            id: 'settings',
            icon: 'settings',
            show: true
        },
        {
          title: 'Sports',
          id: 'sports',
          icon: 'sports',
          show: true,
        }
    ],
    renderPage: undefined,
    username: undefined,
    password: undefined,
    snackbar: {
      show: false,
      text: '',
      color: 'error'
    },
    hidePassword: true,
    rules: {
      username: [
        v => !!v || 'This field is required',
        (v) => (/^([^ ]*)$/g).test(v) || 'Invalid username'
      ],
      password: [
        v => !!v || 'This field is required'
      ]
    },
    get token() {
      return localStorage.getItem('token') || undefined;
    },
    set token(value) {
      localStorage.setItem('token', value);
    },
    get menuActiveElement() {
      return localStorage.getItem('menuActiveElement') || this.menu_items[0].id;
    },
    set menuActiveElement(value) {
      localStorage.setItem('menuActiveElement', value);
    }
  }), 
  created: async function () {
    for (let item of this.menu_items) {
      if (item.id === this.menuActiveElement) {
        this.updateAppBar(item.title);
      }
    }

    if (this.token) {
      try {
        let options = {
            method: 'POST',
            data: {
              token: this.token
            },
            url: `${this.serverURL}/api/v1/admin/verify/jwt`,
        }
        const {master, token} = (await axios(options))?.data;
        this.token = token;
        this.updateMenuItems(master);
        this.renderPage = 'main';
      } catch (err) {
          const {status} = err.response;
          if (status === 401) {
            localStorage.clear();
            this.renderPage = 'login';
          } else if (status === 440) {
            if (this.renderPage === 'login') {
              this.snackbar = {
                  text: 'Your session expired, please login again!',
                  color: 'error',
                  show: true
              }
            }
            this.logout();
          } else {
            this.renderPage = 'login';
            this.snackbar = {
                text: 'There was an error with server communication. Check console for more informations!',
                color: 'error',
                show: true
            }
            console.error(err);
          }
      }
    } else {
      this.renderPage = 'login';
    }
  },
  computed: {
    formIsValid () {
      return (
        this.username === '' ||
        this.username === undefined ||
        this.password === '' ||
        this.password === undefined
      )
    }
  },
  methods: {
    updateAppBar (text) {
      this.appBarText = text;
    },
    updateMenuItems (master) {
      this.menu_items = this.menu_items.map((item) => {
        if (item.id === 'admins') {
          item.show = master;
        }

        return item;
      }) 
    },
    async submitInfos () {
      try {
        let options = {
          method: 'POST',
          data: {
              username: this.username,
              password: this.password
          },
          url: `${this.serverURL}/api/v1/admin/verify/credentials`,
        }
        const {token, master} = (await axios(options))?.data;
        this.token = token;
        this.updateMenuItems(master);
        this.renderPage = 'main'
      } catch (err) {
        const {status} = err.response;
        if (status === 401) {
          this.snackbar = {
              text: 'Invalid credentials! Try again!',
              color: 'error',
              show: true
          }
        } else {
          console.error(err);
          this.snackbar = {
              text: 'There was an error while sending the infos to server. Check console for more informations!',
              color: 'error',
              show: true
          }
        }
      }

      this.$refs.form.reset();
    },
    logout () {
      this.renderPage = 'login';
      localStorage.clear();
    }
  }
};
</script>
