<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-autocomplete
          :items="timezones"
          label="Timezone"
          v-model="timezone"
          class="mt-6"
          @change="fetchEvents"
        ></v-autocomplete>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="events"
          :search="search"
          item-key="_id"
          :loading="tableLoading"
          :footer-props="footerProps"
          :sort-by="['time.timestamp']"
          loading-text="Loading events. Please wait ..."
          class="elevation-1"
          :custom-filter="customFilter"
        >
          <template v-slot:[`item.time.timestamp`]="{ item }">
            {{ formatTime(item.time.timestamp) }}
          </template>
          <template v-slot:[`item.teams`]="{ item }">
            {{ formatEvent(item) }}
          </template>
          <template v-slot:top>
            <v-row>
              <v-btn color="primary" dark class="mb-2 ml-3" @click="fetchEvents"
                ><v-icon>refresh</v-icon>Refresh</v-btn
              >
              <v-btn
                color="error"
                dark
                class="mb-2 mr-2 ml-1"
                @click="multipleDelete"
                ><v-icon>delete</v-icon>Multiple Delete</v-btn
              >
            </v-row>
            <v-dialog
              v-model="dialog.show"
              max-width="800px"
              v-if="dialog.action === 'edit'"
              @keydown.esc="close"
            >
              <v-card :disabled="loading.editSave">
                <v-toolbar dark color="warning" dense flat>
                  <v-toolbar-title class="white--text"
                    ><v-icon class="mb-1 mr-1">event</v-icon>Edit
                    event</v-toolbar-title
                  >
                </v-toolbar>
                <v-card-text>
                  <v-form v-model="isFormValid">
                    <v-text-field
                      v-model="editedItem._id"
                      label="ID"
                      disabled
                    ></v-text-field>
                    <v-text-field
                      :value="formatTime(editedItem.time.timestamp)"
                      @input="updateTime"
                      label="Time"
                      :rules="rules.time"
                    ></v-text-field>
                    <v-text-field
                      v-model="editedItem.competition"
                      label="Competition"
                    ></v-text-field>
                    <v-text-field
                      v-model="editedItem.teams.home"
                      label="Home Team"
                      v-if="editedItem.teams"
                    ></v-text-field>
                    <v-text-field
                      v-model="editedItem.teams.away"
                      label="Away Team"
                      v-if="editedItem.teams"
                    ></v-text-field>
                    <v-text-field
                      v-model="editedItem.fight"
                      label="Fight"
                      v-if="editedItem.fight"
                    ></v-text-field>
                    <v-text-field
                      v-model="editedItem.race"
                      label="Race"
                      v-if="editedItem.race"
                    ></v-text-field>
                    <v-text-field
                      v-model="editedItem.channel"
                      label="Channel"
                    ></v-text-field>
                    <v-text-field
                      v-model="editedItem.duration"
                      label="Duration"
                    ></v-text-field>
                    <v-text-field
                      v-model="editedItem.activeFeed"
                      label="Active Feed"
                      v-if="editedItem.activeFeed !== undefined"
                    ></v-text-field>
                  </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="error" @click="close">
                    <v-icon small class="mr-1">close</v-icon>Cancel
                  </v-btn>
                  <v-btn
                    class="ma-2"
                    :loading="loading.editSave"
                    color="success"
                    @click="save"
                    :disabled="!isFormValid || loading.editSave"
                  >
                    <v-icon small class="mr-1">save</v-icon>Save
                    <template v-slot:loader>
                      <span class="custom-loader">
                        <v-icon light>mdi-cached</v-icon>
                      </span>
                    </template>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="dialog.show"
              max-width="800px"
              v-if="dialog.action === 'delete'"
              @keydown.esc="close"
              persistent
            >
              <v-card :disabled="loading.multipleDelete">
                <v-toolbar dark color="error" dense flat>
                  <v-toolbar-title class="white--text"
                    ><v-icon class="mb-1 mr-1">delete</v-icon>Delete multiple
                    events</v-toolbar-title
                  >
                </v-toolbar>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-date-picker v-model="dates" range></v-date-picker>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="dateRangeText"
                          label="Date range"
                          prepend-icon="mdi-calendar"
                          readonly
                        ></v-text-field>
                        <v-text-field
                          v-model="startTime"
                          label="Start Time"
                          prepend-icon="mdi-clock"
                          :rules="rules.hour"
                        ></v-text-field>
                        <v-text-field
                          v-model="endTime"
                          label="End Time"
                          prepend-icon="mdi-clock"
                          :rules="rules.hour"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="close">
                    <v-icon small class="mr-1">close</v-icon>Cancel
                  </v-btn>
                  <v-btn
                    color="info"
                    @click="multipleDelete"
                    :disabled="dates.length < 2 || loading.multipleDelete"
                    :loading="loading.multipleDelete"
                  >
                    <v-icon small class="mr-1">delete</v-icon>Delete Multiple
                    <template v-slot:loader>
                      <span class="custom-loader">
                        <v-icon light>mdi-cached</v-icon>
                      </span>
                    </template>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <delete-dialog ref="confirm"></delete-dialog>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
            <v-icon small @click="deleteItem(item)">delete</v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar.show = false">
          <v-icon>clear</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import moment from "moment-timezone";
import axios from "axios";
import _ from "lodash";
import parser from "../schedule_parser/parser";
import ConfirmationDialog from "./common/confirmationDialog";

export default {
  name: "Events",
  components: {
    "delete-dialog": ConfirmationDialog,
  },
  data: () => ({
    tableLoading: false,
    headers: [
      {
        text: "Time",
        align: "start",
        sortable: true,
        value: "time.timestamp",
      },
      {
        text: "Competition",
        align: "start",
        sortable: true,
        value: "competition",
      },
      {
        text: "Event",
        align: "start",
        sortable: false,
        value: "teams",
      },
      {
        text: "Duration",
        align: "start",
        sortable: true,
        value: "duration",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
      },
    ],
    footerProps: {
      "items-per-page-options": [-1, 15, 30, 50],
    },
    events: [],
    snackbar: {
      show: false,
      text: "",
      color: "primary",
    },
    search: "",
    timezones: [],
    timezone: undefined,
    dialog: {
      show: false,
      action: "",
    },
    editedItem: {},
    rules: {
      time: [
        (v) => {
          if (/^\d{2}:\d{2} \d{2}\/\d{2}\/\d{4}$/g.test(v)) {
            let timeArr = /^(\d{2}):(\d{2}) (\d{2})\/(\d{2})\/(\d{4})$/g.exec(
              v
            );
            let timeObj = {
              year: timeArr[5],
              month: (parseInt(timeArr[4]) - 1).toString(),
              day: timeArr[3],
              hour: timeArr[1],
              minute: timeArr[2],
            };

            return moment(timeObj).isValid() || "Invalid time";
          }

          return "Invalid time format";
        },
      ],
      hour: [
        (v) => {
          if (/^\d{2}:\d{2}$/g.test(v)) {
            let timeArr = /^(\d{2}):(\d{2})$/g.exec(v);
            
            const hour = parseInt(timeArr[1]);
            const minute = parseInt(timeArr[2]);
            
            if (hour < 0 || hour > 24) {
              return "Invalid hour"
            }

            if (minute < 0 || minute > 59) {
              return "Invalid hour"
            } 

            return true;
          } else {
            return "Invalid hour format";
          }
        }
      ]
    },
    loading: {
      editSave: false,
      multipleDelete: false,
    },
    dates: [],
    startTime: "00:00",
    endTime: "23:59",
    sportsRegex: {},
    isFormValid: false,
  }),
  created: async function () {
    this.timezones = moment.tz.names();
    this.timezone = moment.tz.guess();

    await this.fetchEvents();
  },
  computed: {
    dateRangeText() {
      if (this.dates.length === 2) {
        return `${this.dates[0]} ${this.startTime} ~ ${this.dates[1]} ${this.endTime}`;
      } else {
        return "";
      }
    },
    formatTime() {
      return (time) =>
        moment.utc(time).tz(this.timezone).format("HH:mm DD/MM/YYYY");
    },
    formatEvent() {
      return (item) => {
        const { fighting, racing, reverseTeams } = this.sportsRegex;
        let text = "";

        if (item.competition.toLowerCase().match(fighting)) {
          text = item.fight;
        } else if (item.competition.toLowerCase().match(racing)) {
          text = item.race;
        } else {
          if (item.teams) {
            if (item.teams.away !== "") {
              if (item.competition.toLowerCase().match(reverseTeams)) {
                text = `${item.teams.away} @ ${item.teams.home}`;
              } else {
                text = `${item.teams.home} - ${item.teams.away}`;
              }
            } else {
              text = item.teams.home;
            }
          }
        }

        return text;
      };
    },
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.events.indexOf(item);
      this.editedItem = Object.assign({}, this.events[this.editedIndex]);
      this.itemRestoreCopy = _.cloneDeep(item);
      this.dialog.show = true;
      this.dialog.action = "edit";
    },
    async deleteItem(item) {
      try {
        let confirmDialogOptions = {
          color: "red",
          agree: {
            text: "Delete",
            color: "error",
            icon: "delete_forever",
          },
          cancel: {
            text: "Cancel",
            color: "",
            icon: "clear",
          },
        };
        if (
          await this.$refs.confirm.open(
            "Delete",
            "Are you sure?",
            confirmDialogOptions
          )
        ) {
          let axiosOptions = {
            method: "DELETE",
            data: {
              eId: item._id,
            },
            url: `${this.serverURL}/api/v1/schedule/event`,
          };
          await axios(axiosOptions);
          this.events.splice(this.events.indexOf(item), 1);
          this.snackbar = {
            text: "Event was succesfully deleted",
            color: "success",
            show: true,
          };
        }
      } catch {
        this.snackbar = {
          text:
            "There was an error while deleting the event. Check console for more informations!",
          color: "error",
          show: true,
        };
      }
    },
    async fetchEvents() {
      this.tableLoading = true;
      try {
        let sports = (await axios.get(`${this.serverURL}/api/v1/parser/sports`))
          ?.data;
        this.sportsRegex = await parser.generateSportsRegex(sports);

        let options = {
          method: "POST",
          data: {},
          url: `${this.serverURL}/api/v1/schedule/events`,
        };
        this.events = (await axios(options))?.data;
      } catch (err) {
        console.error(err);
        this.snackbar = {
          text:
            "There was an error while updating the event. Check console for more informations!",
          color: "error",
          show: true,
        };
      }
        
      this.tableLoading = false;
    },
    close() {
      this.dialog.show = false;
      if (this.dialog.action === "edit") {
        this.events.splice(this.editedIndex, 1, this.itemRestoreCopy);
      }
    },
    generateTimestampFromString(timeStr) {
      let timeArr = /^(\d{2}):(\d{2}) (\d{2})\/(\d{2})\/(\d{4})$/g.exec(
        timeStr
      );
      let timeObj = {
        year: timeArr[5],
        month: (parseInt(timeArr[4]) - 1).toString(),
        day: timeArr[3],
        hour: timeArr[1],
        minute: timeArr[2],
      };
      let time = moment.tz(timeObj, this.timezone).utc().format();

      return time;
    },
    updateTime(time) {
      if (this.rules.time[0](time) === true) {
        this.editedItem.time.timestamp = this.generateTimestampFromString(time);
      }
    },
    async save() {
      this.loading.editSave = true;
      Object.assign(this.events[this.editedIndex], this.editedItem);

      try {
        let options = {
          method: "PUT",
          data: this.editedItem,
          url: `${this.serverURL}/api/v1/schedule/event`,
        };
        await axios(options);

        this.snackbar = {
          text: "Event was succesfully updated",
          color: "success",
          show: true,
        };
      } catch (err) {
        console.error(err);
        this.snackbar = {
          text:
            "There was an error while updating the event. Check console for more informations!",
          color: "error",
          show: true,
        };
        this.close();
      }

      this.loading.editSave = false;
      this.dialog.show = false;
    },
    async multipleDelete() {
      if (this.dialog.show === false) {
        this.dates = [];
        this.dialog.show = true;
        this.dialog.action = "delete";
      } else {
        this.loading.multipleDelete = true;
        let confirmDialogOptions = {
          color: "red",
          width: 300,
          agree: {
            text: "Delete Multiple",
            color: "error",
            icon: "delete_forever",
          },
          cancel: {
            text: "Cancel",
            color: "",
            icon: "clear",
          },
        };
        if (
          await this.$refs.confirm.open(
            "Delete Multiple Events",
            `Are you sure you want to delete all events from ${this.dates[0]} ${this.startTime} to ${this.dates[1]} ${this.endTime}?`,
            confirmDialogOptions
          )
        ) {
          try {
            let axiosOptions = {
              method: "DELETE",
              data: {
                eId: undefined,
              },
              url: `${this.serverURL}/api/v1/schedule/event`,
            };
            let start = moment(`${this.dates[0]} ${this.startTime}`);
            let end = moment(`${this.dates[1]} ${this.endTime}`);
            let deletedEvents = [];
            for (let event of this.events) {
              let eventMoment = moment(event.time.timestamp);
              if (start.isBefore(eventMoment) && end.isAfter(eventMoment) || start.isSame(eventMoment) || end.isSame(eventMoment)) {
                deletedEvents.push(event);
              }
            }
            for (let event of deletedEvents) {
              axiosOptions.data.eId = event._id;
              await axios(axiosOptions);
              this.events.splice(this.events.indexOf(event), 1);
            }
            this.snackbar = {
              text: `${deletedEvents.length} events was succesfully deleted`,
              color: "success",
              show: true,
            };
          } catch {
            this.snackbar = {
              text:
                "There was an error while deleting the events. Check console for more informations!",
              color: "error",
              show: true,
            };
          }
        }

        this.dialog.show = false;
        this.loading.multipleDelete = false;
      }
    },
    customFilter(value, search, item) {
      if (this.formatTime(item.time.timestamp).indexOf(search) !== -1) {
        return true;
      }

      if (this.formatEvent(item).toLowerCase().indexOf(search.toLowerCase()) !== -1) {
        return true;
      }

      if (item.competition.toLowerCase().indexOf(search.toLowerCase()) !== -1) {
        return true;
      }

      return false;
    }
  },
};
</script>
