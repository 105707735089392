<template>
    <v-container fluid fill-height>
        <v-row>
            <v-layout align-center justify-center>
                <v-flex shrink>
                    <v-alert
                    prominent
                    type="error"
                    v-for="(error, index) in errors"
                    :key="`error-${index}`"
                    >
                        <v-row align="center">
                            {{error}}
                        </v-row>
                    </v-alert>
                    <v-alert type="info"  v-if="errors.length">
                        For more infos you check the developer console.
                    </v-alert>
                    <v-alert
                        outlined
                        type="success"
                        text
                        v-if="succesfullUpload"
                    >
                        Schedule was uploaded succesfully.
                    </v-alert>
                </v-flex>
            </v-layout>
        </v-row>
        <v-row>    
            <v-textarea
                outlined
                label="Schedule"
                v-model="schedule"
                :disabled="buttonClicked"
                :height="windowHeight"
            ></v-textarea>
        </v-row>
        <v-row>
            <v-layout align-center justify-center>
                <v-flex shrink>
                    <v-btn      
                        :loading="buttonClicked"
                        :disabled="buttonClicked"
                        color="blue-grey"
                        class="ma-2 white--text"
                        @click="parseSchedule"
                    >
                        Upload
                        <v-icon
                            right
                            dark
                        >
                            mdi-cloud-upload
                        </v-icon>
                    </v-btn>
                </v-flex>
            </v-layout>
        </v-row>
    </v-container>
</template>

<script>
    import scheduleParser from '../schedule_parser/parser';
    import axios from 'axios';
    
    export default {
        name: 'UploadSchedule',

        data: () => ({
            loader: null,
            buttonClicked: false,
            succesfullUpload: false,
            schedule: '',
            errors: []
        }),
        computed: {
            windowHeight () {
                return (window.innerHeight-250);
            }
        },
        methods: {
            async parseSchedule() {
                this.buttonClicked = true;
                this.errors = [];
                this.succesfullUpload = false;

                let textSchedule = this.schedule;
                textSchedule = textSchedule.split(/\r?\n/g);

                await scheduleParser.generateSportsRegex();

                let time = {};
                let jsonSchedule = [];

                for (let line of textSchedule) {
                    if (line !== '') {
                        if (line.match(/.*\d{2}.\d{2}.\d{4}/g)) {
                            line = (/.*(\d{2}).(\d{2}).(\d{4})/g).exec(line);
                            time = {
                                year: line[3],
                                month: (parseInt(line[2])-1).toString(),
                                day: line[1],
                                hour: '00',
                                minute: '00'
                            }
                        } else {
                            try {
                                let event = await scheduleParser.parseEvent(time, line);
                
                                jsonSchedule.push(event);
                            } catch (err) {
                                console.error(err);
                                this.errors.push(`Problem with line: '${line}'`);
                            }
                        }
                    }
                }

                try {
                    if (jsonSchedule.length) {
                        await this.sendSchedule(jsonSchedule);
                    }
                } catch (err) {
                    console.error(err);
                    this.errors.push(`There was a problem while uploading the schelue.`)
                }

                if (this.errors.length) {
                    this.$vuetify.goTo(0);
                } else {
                    this.schedule = '';
                    this.succesfullUpload = true;
                }

                this.buttonClicked = false;
            },
            async sendSchedule(jsonSchedule) {
                try {
                    let options = {
                        method: 'POST',
                        data: jsonSchedule,
                        url: `${this.serverURL}/api/v1/schedule/update`,
                    }
                    let response = await axios(options);
                    if(parseInt(response.status) === 200) {
                        try {
                            options = {
                                method: 'GET',
                                data: {},
                                url: `${this.serverURL}/api/v1/subscribers`,
                            }
                            let subscribers = (await axios(options))?.data;
                            if (subscribers) {
                                try {
                                    options = {
                                        method: 'POST',
                                        data: {},
                                        url: `${this.serverURL}/api/v1/schedule/events`
                                    }
                                    let events = (await axios(options))?.data;
                                    if (events) {
                                        for (const subscriber of subscribers) {
                                            if (subscriber.subscribed) {
                                                try {
                                                    options = {
                                                        method: 'POST',
                                                        data: {
                                                            events: events,
                                                            subscriber: subscriber
                                                        },
                                                        url: `${this.serverURL}/api/v1/subscribers/notify`
                                                    }
                                                    await axios(options);
                                                } catch(err) {
                                                    console.error(err);
                                                    this.errors.push(`There was a problem sending the events`);
                                                }
                                            }
                                        }
                                    }
                                } catch (err) {
                                    console.error(err);
                                    this.errors.push(`There was a problem getting the events with duration added.`);
                                }
                            }
                        } catch (err) {
                            console.error(err);
                            this.errors.push(`There was a problem getting the subscribers.`);
                        }
                    }
                } catch (err) {
                    console.error(err);
                    this.errors.push(`There was a problem while uploading the schelue.`)
                }
            }
        }
    }
</script>
