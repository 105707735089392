<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="sports"
          :search="search"
          item-key="_id"
          :loading="tableLoading"
          :footer-props="footerProps"
          loading-text="Loading sports. Please wait ..."
          class="elevation-1"
        >
          <template v-slot:top>
            <v-row>
              <v-btn
                color="primary"
                dark
                class="mb-2 mr-1 ml-3"
                @click="fetchSports"
                ><v-icon>refresh</v-icon>Refresh</v-btn
              >

              <v-btn
                color="green darken-1"
                dark
                class="mb-2 mr-2 ml-1"
                @click="addDialog"
                ><v-icon class="mr-1">add</v-icon>Add Sport</v-btn
              >
            </v-row>
            <v-dialog v-model="dialog.status" persistent max-width="800px">
              <v-card v-if="dialog.action === 'edit'">
                <v-toolbar dark color="warning" dense flat>
                  <v-toolbar-title class="white--text"
                    ><v-icon class="mb-1 mr-1">assignment</v-icon>Edit
                    sport</v-toolbar-title
                  >
                </v-toolbar>

                <v-card-text>
                  <v-container>
                    <v-text-field
                      v-model="editedItem.sport"
                      label="Sport"
                    ></v-text-field>
                    <v-text-field
                      v-model="editedItem.duration"
                      label="Duration"
                    ></v-text-field>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="error" @click="close" :disabled="loading"
                    ><v-icon small class="mr-1">close</v-icon>Cancel</v-btn
                  >
                  <v-btn color="success" @click="save" :disabled="loading"
                    ><v-icon small class="mr-1">save</v-icon>Save</v-btn
                  >
                </v-card-actions>
              </v-card>

              <v-card v-if="dialog.action === 'add'">
                <v-toolbar dark color="success" dense flat>
                  <v-toolbar-title class="white--text"
                    ><v-icon class="mb-1 mr-1">add</v-icon>Add
                    sport</v-toolbar-title
                  >
                </v-toolbar>

                <v-card-text>
                  <v-container>
                    <v-text-field
                      v-model="editedItem.sport"
                      label="Sport"
                    ></v-text-field>
                    <v-text-field
                      v-model="editedItem.duration"
                      label="Duration"
                    ></v-text-field>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="error" @click="close" :disabled="loading"
                    ><v-icon small class="mr-1">close</v-icon>Cancel</v-btn
                  >
                  <v-btn
                    color="success"
                    @click="addUpdateSport"
                    :disabled="loading"
                    ><v-icon small class="mr-1">add_circle</v-icon>Add</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>

            <delete-dialog ref="confirm"></delete-dialog>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
            <v-icon small @click="deleteItem(item)">delete</v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar.show = false">
          <v-icon>clear</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import ConfirmationDialog from "./common/confirmationDialog";

export default {
  name: "Sports",
  components: {
    "delete-dialog": ConfirmationDialog,
  },
  data: () => ({
    tableLoading: false,
    headers: [
      {
        text: "Sport",
        align: "start",
        sortable: true,
        value: "sport",
      },
      {
        text: "Duration",
        align: "start",
        sortable: true,
        value: "duration",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
      },
    ],
    footerProps: {
      "items-per-page-options": [-1, 15, 30, 50],
    },
    sports: [],
    snackbar: {
      show: false,
      text: "",
      color: "primary",
    },
    search: "",
    dialog: {
      status: false,
      action: "edit",
    },
    delDialog: false,
    loading: false,
    editedItem: {},
  }),
  created: async function () {
    await this.fetchSports();
  },
  methods: {
    async fetchSports() {
      this.tableLoading = true;
      try {
        let sports = [];
        let response = (await axios.get(`${this.serverURL}/api/v1/sports`))
          ?.data;
        for (let sport in response.duration) {
            if (sport !== "default"){
                let elem = {
                sport: sport,
                duration: response.duration[sport],
                };
                sports.push(elem);
            }
        }
        this.sports = sports;
      } catch (err) {
        console.error(err);
        this.snackbar = {
          text:
            "There was an error while fetching sports. Check console for more informations!",
          color: "error",
          show: true,
        };
      }
      this.tableLoading = false;
    },
    editItem(item) {
      this.editedIndex = this.sports.indexOf(item);
      this.editedItem = Object.assign({}, this.sports[this.editedIndex]);
      this.itemRestoreCopy = _.cloneDeep(item);
      this.dialog.status = true;
      this.dialog.action = "edit";
    },
    async addDialog() {
      this.editedItem = {
        sport: "",
        duration: "",
      };
      this.dialog.status = true;
      this.dialog.action = "add";
    },
    async deleteItem(item) {
      try {
        let confirmDialogOptions = {
          color: "red",
          agree: {
            text: "Delete",
            color: "error",
            icon: "delete_forever",
          },
          cancel: {
            text: "Cancel",
            color: "",
            icon: "clear",
          },
        };
        if (
          await this.$refs.confirm.open(
            "Delete",
            "Are you sure?",
            confirmDialogOptions
          )
        ) {
          let options = {
            method: "DELETE",
            url: `${this.serverURL}/api/v1/sports/delete`,
            data: item,
          };
          await axios(options);
          this.sports.splice(this.sports.indexOf(item), 1);
          this.snackbar = {
            text: "Sport was succesfully deleted",
            color: "success",
            show: true,
          };
        }
      } catch (err) {
        console.error(err);
        this.snackbar = {
          text:
            "There was an error while deleting the sport. Check console for more informations!",
          color: "error",
          show: true,
        };
      }
      this.delDialog = false;
    },
    async addUpdateSport() {
      this.loading = true;
      try {
        let options = {
          method: "POST",
          url: `${this.serverURL}/api/v1/sports/update`,
          data: this.editedItem,
        };
        await axios(options);
        this.sports.push(this.editedItem);
        this.loading = false;
        this.snackbar = {
          text: "Sport was succesfully added",
          color: "success",
          show: true,
        };
      } catch (err) {
        console.error(err.response.data);
        this.snackbar = {
          text:
            "There was an error while adding the sport. Check console for more informations!",
          color: "error",
          show: true,
        };
      }

      this.dialog.status = false;
      this.loading = false;
    },
    close() {
      this.delDialog = false;
      this.dialog.status = false;
      this.loading = true;
      setTimeout(() => {
        if (this.action === "edit") {
          this.sports.splice(this.editedIndex, 1, this.itemRestoreCopy);
        }
        this.editedItem = {};
        this.editedIndex = -1;
      }, 300);
      this.loading = false;
    },
    async save() {
      try {
        this.loading = true;
        const oldSport = this.sports[this.editedIndex].sport;
        Object.assign(this.sports[this.editedIndex], this.editedItem);
        this.editedItem.oldSport = oldSport;
        let options = {
          method: "POST",
          url: `${this.serverURL}/api/v1/sports/update`,
          data: this.editedItem,
        };
        await axios(options);
        this.loading = false;
        this.snackbar = {
          text: "Sport was succesfully edited",
          color: "success",
          show: true,
        };
      } catch (err) {
        console.error(err);
        this.snackbar = {
          text:
            "There was an error while saving the sport. Check console for more informations!",
          color: "error",
          show: true,
        };
      }
      this.dialog.status = false;
      this.loading = false;
    },
  },
};
</script>
