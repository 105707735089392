const bcrypt = require('bcryptjs');

//Create a promise for generating salt
async function generateSalt(saltRounds) {
  return new Promise((resolve, reject) => {
    bcrypt.genSalt(saltRounds, (err, salt) => {
      if (err) reject(err);
      else resolve(salt);
    });
  });
}

//Encrypt the token using the generated salt
async function generateToken(token) {
  try {
    let salt = await generateSalt(10);
    let hashToken = await bcrypt.hash(token, salt);
    return hashToken;
  } catch (err) {
    console.error(err);
  }
}

async function compareToken(token, serverToken) {
  try {
    let isMatch = await bcrypt.compare(token, serverToken);
    return isMatch;
  } catch (err) {
    console.error(err);
  }
}

module.exports = {
  generateToken,
  compareToken
}