<template>
  <v-container fluid fill-height>
    <v-row>
      <h2>Special Sports Classes</h2>
      <v-expansion-panels v-model="panel" class="ml-5 mt-2">
        <v-expansion-panel
          v-for="(value, key) in sports"
          :key="key"
          @click="generateTextFieldContent(key)"
        >
          <v-expansion-panel-header>
            {{ generateSportsTitle(key) }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-text-field
              :placeholder="generateSportsTitle(key)"
              :id="key"
              v-model="textFieldContent[key]"
            ></v-text-field>
            <v-btn small color="success" dark @click="saveSportsChanges(key)">
              <v-icon>save</v-icon>
              Save
            </v-btn>
            <v-btn
              small
              color="error"
              dark
              class="ml-2"
              @click="discardSportsChanges(key)"
            >
              <v-icon>cancel</v-icon>
              Discard
            </v-btn>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <v-row>
      <h2>Password manager</h2>
      <v-expansion-panels v-model="password" class="ml-5 mt-2">
        <v-expansion-panel>
          <v-expansion-panel-header> Change password </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-form ref="form">
              <v-text-field
                v-model="user.oldPassword"
                label="Old password"
                prepend-icon="lock"
                :append-icon="hidePassword ? 'visibility' : 'visibility_off'"
                @click:append="() => (hidePassword = !hidePassword)"
                :type="hidePassword ? 'password' : 'text'"
              ></v-text-field>
              <v-text-field
                v-model="user.newPassword"
                label="New password"
                :rules="[rules.required, rules.min]"
                prepend-icon="lock"
                :append-icon="hideNewPassword ? 'visibility' : 'visibility_off'"
                @click:append="() => (hideNewPassword = !hideNewPassword)"
                :type="hideNewPassword ? 'password' : 'text'"
              ></v-text-field>
              <v-text-field
                v-model="user.reNewPassword"
                label="Check new password"
                :rules="[rules.required, rules.min, passwordConfirmationRule]"
                prepend-icon="lock"
                :append-icon="hideReNewPassword ? 'visibility' : 'visibility_off'"
                @click:append="() => (hideReNewPassword = !hideReNewPassword)"
                :type="hideReNewPassword ? 'password' : 'text'"
              ></v-text-field>
            </v-form>
            <v-btn
              small
              color="success"
              @click="updatePassword()"
              :disabled="
                user.newPassword !== user.reNewPassword ||
                user.newPassword === '' ||
                user.reNewPassword === ''
              "
            >
              <v-icon>update</v-icon>
              Update
            </v-btn>
            <v-btn small color="error" class="ml-2" @click="discardPassword()">
              <v-icon>cancel</v-icon>
              Discard
            </v-btn>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar.show = false">
          <v-icon>clear</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "Settings",

  data: () => ({
    sports: undefined,
    panel: [],
    textFieldContent: {},
    user: {
      oldPassword: "",
      newPassword: "",
      reNewPassword: "",
    },
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
    },
    password: false,
    hidePassword: true,
    hideNewPassword: true,
    hideReNewPassword: true,
    snackbar: {
      show: false,
      text: "",
      color: "primary",
    },
    get token() {
      return localStorage.getItem("token") || undefined;
    },
  }),
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.user.newPassword === this.user.reNewPassword ||
        "Password must match";
    },
  },
  created: async function () {
    try {
      this.sports = (
        await axios.get(`${this.serverURL}/api/v1/parser/sports`)
      )?.data;
    } catch (err) {
      console.error(err);
      this.snackbar = {
        text:
          "There was an error fetching sports infos. Check console for more informations!",
        color: "error",
        show: true,
      };
    }
  },
  methods: {
    generateSportsTitle(key) {
      key = key.split(/(?=[A-Z])/);
      key[0] = key[0].charAt(0).toUpperCase() + key[0].slice(1);
      key = key.join(" ");

      return key;
    },
    generateTextFieldContent(key) {
      if (this.textFieldContent[key] === undefined) {
        this.textFieldContent[key] = this.sports[key].join(", ");
      }
    },
    async saveSportsChanges(key) {
      this.sports[key] = this.textFieldContent[key]
        .replace(/\s/g, "")
        .split(",");

      let options = {
        method: "POST",
        data: this.sports,
        url: `${this.serverURL}/api/v1/parser/sports`,
      };
      try {
        await axios(options);
        this.snackbar.text = "All changes have been successfully saved.";
        this.snackbar.visible = true;
      } catch (err) {
        console.error(err);
        this.snackbar = {
          text:
            "There was an error while sending the modifications. Check console for more informations!",
          color: "error",
          show: true,
        };
      }
    },
    discardSportsChanges(key) {
      this.panel = [];
      this.textFieldContent[key] = undefined;
    },
    async updatePassword() {
      try {
        let options = {
          method: "PUT",
          data: {
            token: this.token,
            oldPassword: this.user.oldPassword,
            newPassword: this.user.newPassword,
          },
          url: `${this.serverURL}/api/v1/admin/password`,
        };
        await axios(options);
        this.discardPassword();
      } catch (err) {
        console.error(err);
        this.snackbar = {
          text:
            "There was an error while sending the modifications. Check console for more informations!",
          color: "error",
          show: true,
        };
      }
    },
    discardPassword() {
      this.$refs.form.reset();
      this.user = {
        oldPassword: "",
        newPassword: "",
        reNewPassword: "",
      };
      this.password = false;
    },
    logout() {
      this.renderPage = "login";
      localStorage.clear();
    },
  },
};
</script>
