<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="subscribers"
          :search="search"
          item-key="_id"
          :loading="tableLoading"
          :footer-props="footerProps"
          loading-text="Loading subscribers. Please wait ..."
          class="elevation-1"
        >
          <template v-slot:top>
            <v-row>
              <v-btn
                color="primary"
                dark
                class="mb-2 mr-1 ml-3"
                @click="fetchSubscribers"
                ><v-icon>refresh</v-icon>Refresh</v-btn
              >

              <v-btn
                color="warning"
                dark
                class="mb-2 mr-1 ml-1"
                @click="notifySubscribers"
                ><v-icon class="mr-1">notifications_active</v-icon>Notify
                all</v-btn
              >

              <v-btn
                color="green darken-1"
                dark
                class="mb-2 mr-2 ml-1"
                @click="addDialog"
                ><v-icon class="mr-1">add</v-icon>Add subscriber</v-btn
              >
            </v-row>
            <v-dialog v-model="dialog.status" persistent max-width="800px">
              <v-card v-if="dialog.action === 'edit'">
                <v-toolbar dark color="warning" dense flat>
                  <v-toolbar-title class="white--text"
                    ><v-icon class="mb-1 mr-1">assignment</v-icon>Edit
                    subscriber</v-toolbar-title
                  >
                </v-toolbar>

                <v-card-text>
                  <v-container>
                    <v-text-field
                      v-model="editedItem._id"
                      label="ID"
                      disabled
                    ></v-text-field>
                    <v-text-field
                      v-model="editedItem.token"
                      label="Token"
                      v-if="editedItem.token"
                      disabled
                    ></v-text-field>
                    <v-text-field
                      v-model="editedItem.url"
                      label="URL"
                      :rules="rules.url"
                    ></v-text-field>
                    <v-text-field
                      v-model="editedItem.uri"
                      label="URI"
                      :rules="rules.uri"
                    ></v-text-field>
                    <v-checkbox
                      v-model="editedItem.subscribed"
                      label="Do you want to auto update this subscriber?"
                    ></v-checkbox>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="error" @click="close" :disabled="loading"
                    ><v-icon small class="mr-1">close</v-icon>Cancel</v-btn
                  >
                  <v-btn
                    color="success"
                    @click="save"
                    :disabled="
                      loading ||
                      !/^https?:\/\/[-a-zA-Z0-9-_%.]*((\.[a-zA-Z0-9]+)|(:[0-9]+))$/g.test(
                        editedItem.url
                      ) ||
                      !/^(\/[\w-]*)+$/g.test(editedItem.uri)
                    "
                    ><v-icon small class="mr-1">save</v-icon>Save</v-btn
                  >
                </v-card-actions>
              </v-card>

              <v-card v-if="dialog.action === 'add'">
                <v-toolbar dark color="success" dense flat>
                  <v-toolbar-title class="white--text"
                    ><v-icon class="mb-1 mr-1">add</v-icon>Add
                    subscriber</v-toolbar-title
                  >
                </v-toolbar>

                <v-card-text>
                  <v-container>
                    <v-text-field
                      v-model="editedItem.url"
                      label="URL"
                      :rules="rules.url"
                    ></v-text-field>
                    <v-text-field
                      v-model="editedItem.uri"
                      label="URI"
                      :rules="rules.uri"
                    ></v-text-field>
                    <v-checkbox
                      v-model="editedItem.subscribed"
                      label="Do you want to auto update this subscriber?"
                    ></v-checkbox>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="error" @click="close" :disabled="loading"
                    ><v-icon small class="mr-1">close</v-icon>Cancel</v-btn
                  >
                  <v-btn
                    color="success"
                    @click="addUpdateSubscriber"
                    :disabled="
                      loading ||
                      !/^https?:\/\/[-a-zA-Z0-9-_%.]*((\.[a-zA-Z0-9]+)|(:[0-9]+))$/g.test(
                        editedItem.url
                      ) ||
                      !/^(\/[\w-]*)+$/g.test(editedItem.uri)
                    "
                    ><v-icon small class="mr-1">add_circle</v-icon>Add</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>

            <delete-dialog ref="confirm"></delete-dialog>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
            <v-icon small @click="deleteItem(item)">delete</v-icon>
            <v-icon v-if="item.status === 'idle'" color="grey" small class="ml-2" @click="notify(item)"
              >notifications_active</v-icon>
            <v-icon v-if="item.status === 'success'" color="success" small class="ml-2" @click="notify(item)"
              >notifications_active</v-icon>
            <v-icon v-if="item.status === 'error'" color="error" small class="ml-2" @click="notify(item)"
              >notifications_active</v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar.show = false">
          <v-icon>clear</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import ConfirmationDialog from "./common/confirmationDialog";
import tokenGenerator from "../token_generator/token";

export default {
  name: "Subscribers",
  components: {
    "delete-dialog": ConfirmationDialog,
  },
  data: () => ({
    tableLoading: false,
    headers: [
      {
        text: "URL",
        align: "start",
        sortable: true,
        value: "url",
      },
      {
        text: "URI",
        align: "start",
        sortable: true,
        value: "uri",
      },
      {
        text: "SUBSCRIBED",
        align: "start",
        sortable: true,
        value: "subscribed",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
      },
    ],
    footerProps: {
      "items-per-page-options": [-1, 15, 30, 50],
    },
    subscribers: [],
    events: [],
    snackbar: {
      show: false,
      text: "",
      color: "primary",
    },
    search: "",
    dialog: {
      status: false,
      action: "edit",
    },
    delDialog: false,
    loading: false,
    editedItem: {},
    rules: {
      url: [
        (url) =>
          /^https?:\/\/[-a-zA-Z0-9-_%.]*((\.[a-zA-Z0-9]+)|(:[0-9]+))$/g.test(
            url
          ) || "Invalid url format!",
      ],
      uri: [(uri) => /^(\/[\w-]*)+$/g.test(uri) || "Invalid uri format"],
    },
  }),
  created: async function () {
    await this.fetchSubscribers();
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.subscribers.indexOf(item);
      this.editedItem = Object.assign({}, this.subscribers[this.editedIndex]);
      this.itemRestoreCopy = _.cloneDeep(item);
      this.dialog.status = true;
      this.dialog.action = "edit";
    },
    async fetchSubscribers() {
      this.tableLoading = true;
      try {
        this.subscribers = (
          await axios.get(`${this.serverURL}/api/v1/subscribers`)
        )?.data;

        this.subscribers = this.subscribers.map((subscriber) => {
          subscriber.status = 'idle';
          return subscriber;
        });

        let options = {
          method: "POST",
          data: {},
          url: `${this.serverURL}/api/v1/schedule/events`,
        };
        this.events = (await axios(options))?.data;
      } catch (err) {
        console.error(err);
        this.snackbar = {
          text:
            "There was an error while fetching subscribers. Check console for more informations!",
          color: "error",
          show: true,
        };
      }
      this.tableLoading = false;
    },
    async notify(item) {
      // let index = this.subscribers.indexOf(item);
      try {
        let options = {
          method: "POST",
          url: `${this.serverURL}/api/v1/subscribers/notify`,
          data: {
            events: this.events,
            subscriber: item,
          },
        };
        let response = (await axios(options))?.data;
        if (response.subscriberStatus === 200) {
          this.snackbar = {
            text: `The subscriber ${item.url} was notified!`,
            color: "success",
            show: true,
          };
          item.status = 'success';
          // this.$set(this.someObject, 'b', 2)
        }
      } catch (err) {

        console.error(err);
        this.snackbar = {
          text:
            "There was an error while notifying a subscriber. Check console for more informations!",
          color: "error",
          show: true,
        };
        item.status = 'error';
      }
    },
    async notifySubscribers() {
      try {
        let confirmDialogOptions = {
          color: "warning",
          agree: {
            text: "Notify all",
            color: "warning",
            icon: "notifications_active",
          },
          cancel: {
            text: "Cancel",
            color: "",
            icon: "clear",
          },
        };
        if (
          await this.$refs.confirm.open(
            "Notify",
            "Are you sure?",
            confirmDialogOptions
          )
        ) {
          try {
            let options = {
              method: "POST",
              data: {},
              url: `${this.serverURL}/api/v1/schedule/events`,
            };
            this.events = (await axios(options))?.data;
            if (this.events) {
              for (const subscriber of this.subscribers) {
                await this.notify(subscriber);
              }
            }
          } catch (err) {
            console.error(err);
            this.snackbar = {
              text:
                "There was an error while getting the events. Check console for more informations!",
              color: "error",
              show: true,
            };
          }
        }
      } catch (err) {
        console.error(err);
        this.snackbar = {
          text:
            "There was an error while notifying subscribers. Check console for more informations!",
          color: "error",
          show: true,
        };
      }
    },
    async addDialog() {
      this.editedItem = {
        token: "",
        url: "",
        uri: "",
        subscribed: false,
      };
      this.dialog.status = true;
      this.dialog.action = "add";
    },
    async deleteItem(item) {
      try {
        let confirmDialogOptions = {
          color: "red",
          agree: {
            text: "Delete",
            color: "error",
            icon: "delete_forever",
          },
          cancel: {
            text: "Cancel",
            color: "",
            icon: "clear",
          },
        };
        if (
          await this.$refs.confirm.open(
            "Delete",
            "Are you sure?",
            confirmDialogOptions
          )
        ) {
          let options = {
            method: "DELETE",
            url: `${this.serverURL}/api/v1/subscribers/delete`,
            data: item,
          };
          await axios(options);
          this.subscribers.splice(this.subscribers.indexOf(item), 1);
          this.snackbar = {
            text: "Subscriber was succesfully deleted",
            color: "success",
            show: true,
          };
        }
      } catch (err) {
        console.error(err);
        this.snackbar = {
          text:
            "There was an error while deleting the subscriber. Check console for more informations!",
          color: "error",
          show: true,
        };
      }
      this.delDialog = false;
    },
    async addUpdateSubscriber() {
      this.loading = true;
      try {
        this.editedItem.token = await tokenGenerator.generateToken(
          this.editedItem.url
        );
        let options = {
          method: "POST",
          url: `${this.serverURL}/api/v1/subscribers/add`,
          data: this.editedItem,
        };
        const subscriber = (await axios(options))?.data;
        this.subscribers.push(subscriber);
        this.loading = false;
        this.snackbar = {
          text: "Subscriber was succesfully added",
          color: "success",
          show: true,
        };
      } catch (err) {
        console.error(err.response.data);
        this.snackbar = {
          text:
            "There was an error while adding the subscriber. Check console for more informations!",
          color: "error",
          show: true,
        };
      }

      this.dialog.status = false;
      this.loading = false;
    },
    close() {
      this.delDialog = false;
      this.dialog.status = false;
      this.loading = true;
      setTimeout(() => {
        if (this.action === "edit") {
          this.subscribers.splice(this.editedIndex, 1, this.itemRestoreCopy);
        }
        this.editedItem = {};
        this.editedIndex = -1;
      }, 300);
      this.loading = false;
    },
    async save() {
      try {
        this.loading = true;
        Object.assign(this.subscribers[this.editedIndex], this.editedItem);
        let options = {
          method: "POST",
          url: `${this.serverURL}/api/v1/subscribers/add`,
          data: this.editedItem,
        };
        await axios(options);
        this.snackbar = {
          text: "Subscriber was succesfully edited.",
          color: "success",
          show: true,
        };
      } catch (err) {
        console.error(err);
        this.snackbar = {
          text:
            "There was an error while saving the subscriber. Check console for more informations!",
          color: "error",
          show: true,
        };
      }
      this.dialog.status = false;
      this.loading = false;
    },
  },
};
</script>
