<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="admins"
          :search="search"
          item-key="_id"
          :loading="tableLoading"
          :footer-props="footerProps"
          loading-text="Loading admins. Please wait ..."
          class="elevation-1"
        >
          <template v-slot:top>
            <v-row>
              <v-btn
                color="primary"
                dark
                class="mb-2 mr-1 ml-3"
                @click="fetchAdmins"
                ><v-icon>refresh</v-icon>Refresh</v-btn
              >

              <v-btn
                color="green darken-1"
                dark
                class="mb-2 mr-2 ml-1"
                @click="addDialog"
                ><v-icon class="mr-1">add</v-icon>Add admin</v-btn
              >
            </v-row>
            <v-dialog v-model="dialog.status" persistent max-width="800px">
              <v-card v-if="dialog.action === 'edit'">
                <v-toolbar dark color="warning" dense flat>
                  <v-toolbar-title class="white--text"><v-icon class="mb-1 mr-1">person</v-icon>Edit admin</v-toolbar-title>
                </v-toolbar>

                <v-card-text>
                  <v-container>
                    <v-text-field
                      v-model="editedItem._id"
                      label="ID"
                      disabled
                    ></v-text-field>
                    <v-text-field
                      v-model="editedItem.username"
                      label="Username"
                      v-if="editedItem.username"
                    ></v-text-field>
                    <v-checkbox
                      v-model="editedItem.master"
                      label="Can this admin edit other admins?"
                    ></v-checkbox>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="error" @click="close"
                    ><v-icon small class="mr-1">close</v-icon>Cancel</v-btn
                  >
                  <v-btn color="success" @click="save"
                    ><v-icon small class="mr-1">save</v-icon>Save</v-btn
                  >
                </v-card-actions>
              </v-card>

              <v-card v-if="dialog.action === 'add'">
                <v-toolbar dark color="success" dense flat>
                  <v-toolbar-title class="white--text"><v-icon class="mb-1 mr-1">add</v-icon>Add admin</v-toolbar-title>
                </v-toolbar>

                <v-card-text>
                  <v-container>
                    <v-text-field
                      v-model="editedItem.username"
                      label="Username"
                      prepend-icon="person"
                    ></v-text-field>
                    <v-text-field
                      id="password"
                      label="Password"
                      ref="password"
                      v-model="editedItem.password"
                      prepend-icon="lock"
                      :append-icon="
                        hidePassword ? 'visibility' : 'visibility_off'
                      "
                      @click:append="() => (hidePassword = !hidePassword)"
                      :type="hidePassword ? 'password' : 'text'"
                      :rules="rules.password"
                    />
                    <v-checkbox
                      v-model="editedItem.master"
                      label="Can this admin edit other admins?"
                    ></v-checkbox>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="error" @click="close"
                    ><v-icon small class="mr-1">close</v-icon>Cancel</v-btn
                  >
                  <v-btn
                    color="success"
                    @click="addAdmin"
                    :disabled="formIsValid"
                  >
                    <v-icon small class="mr-1">add_circle</v-icon>Add</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
            <delete-dialog ref="confirm"></delete-dialog>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
            <v-icon small @click="deleteItem(item)">delete</v-icon>
          </template>
        </v-data-table>
        <v-snackbar v-model="snackbar.status" :color="snackbar.color">
          {{ snackbar.text }}
        </v-snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import ConfirmationDialog from "./common/confirmationDialog";

export default {
  name: "Admins",
  components: {
    "delete-dialog": ConfirmationDialog,
  },
  data: () => ({
    tableLoading: false,
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: true,
        value: "_id",
      },
      {
        text: "Username",
        align: "start",
        sortable: true,
        value: "username",
      },
      {
        text: "Master",
        align: "start",
        sortable: true,
        value: "master",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
      },
    ],
    footerProps: {
      "items-per-page-options": [15, 30, 50, -1],
    },
    admins: [],
    snackbar: {
      show: false,
      text: "",
      color: "primary",
    },
    search: "",
    dialog: {
      status: false,
      action: "edit",
    },
    editedItem: {},
    hidePassword: true,
    rules: {
      username: [
        (v) => !!v || "This field is required",
        (v) => /^([^ ]*)$/g.test(v) || "Invalid username",
      ],
      password: [(v) => !!v || "This field is required"],
    },
  }),
  created: async function () {
    this.fetchAdmins();
  },
  computed: {
    formIsValid() {
      return (
        this.editedItem.username === "" ||
        this.editedItem.username === undefined ||
        this.editedItem.password === "" ||
        this.editedItem.password === undefined
      );
    },
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.admins.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog.status = true;
      this.dialog.action = "edit";
    },
    async fetchAdmins() {
      this.tableLoading = true;
      try {
        this.admins = (
        await axios.get(`${this.serverURL}/api/v1/admins`)
        )?.data;
      } catch (err) {
        console.error(err);
        this.snackbar.color = "error";
        this.snackbar.text =
          "There was an error while fetching the admin. Check console for more informations!";
        this.snackbar.show = true;
      }
      this.tableLoading = false;
    },
    async deleteItem(item) {
      try {
        let confirmDialogOptions = {
          color: "red",
          agree: {
            text: "Delete",
            color: "error",
            icon: "delete_forever",
          },
          cancel: {
            text: "Cancel",
            color: "",
            icon: "clear",
          },
        };
        if (
          await this.$refs.confirm.open(
            "Delete",
            "Are you sure?",
            confirmDialogOptions
          )
        ) {
          let axiosOptions = {
            method: "DELETE",
            data: {
              aId: item._id,
            },
            url: `${this.serverURL}/api/v1/admin`,
          };
          await axios(axiosOptions);
          this.admins.splice(this.admins.indexOf(item), 1);
          this.snackbar = {
            text: "Event was succesfully deleted",
            color: "success",
            show: true,
          };
        }
      } catch {
        this.snackbar = {
          text:
            "There was an error while deleting the event. Check console for more informations!",
          color: "error",
          show: true,
        };
      }
    },
    addDialog() {
      this.editedItem = {
        username: "",
        password: "",
        master: false,
      };
      this.dialog.status = true;
      this.dialog.action = "add";
    },
    async addAdmin() {
      try {
        let options = {
          method: "PUT",
          url: `${this.serverURL}/api/v1/admin`,
          data: this.editedItem,
        };
        await axios(options);
        this.fetchAdmins();
      } catch (err) {
        console.error(err);
      }
      this.dialog.status = false;
    },
    close() {
      this.dialog.status = false;
      this.hidePassword = true;
      setTimeout(() => {
        this.editedItem = {};
        this.editedIndex = -1;
      }, 300);
    },
    async save() {
      try {
        Object.assign(this.admins[this.editedIndex], this.editedItem);
        let options = {
          method: "PUT",
          url: `${this.serverURL}/api/v1/admin`,
          data: this.editedItem,
        };
        await axios(options);
      } catch (err) {
        console.error(err);
      }
      this.dialog.status = false;
    },
  },
};
</script>
