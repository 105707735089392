const moment = require('moment-timezone');
const axios = require('axios');

let serverURL = '';
if (process.env.NODE_ENV === 'development') {
    serverURL = 'http://localhost:5100';
}

let sportsRegex = undefined;

async function generateSportsRegex() {
    const sports = (await axios.get(`${serverURL}/api/v1/parser/sports`))?.data;

    sportsRegex = {}
 
    for (let key in sports) {
        sportsRegex[key] = ''

        for (let sport of sports[key]) {
            sportsRegex[key] += sport.trim().toLowerCase() + '|'
        }
        sportsRegex[key] = sportsRegex[key].slice(0,-1);
    }

    return sportsRegex;
}

async function parseEvent(time, line) {
    let event = {
        competition: '',
        channel: '0',
        time: {
            timestamp: '0',
            timeZone: 'UTC'
        },
        infos: ''
    }

    /*
        line[1] --> Hour
        line[2] --> Minute
        line[3] --> Competition
        line[4] --> Event
        line[5] --> Extra Infos
        line[6] --> Channel
    */
    line = (/.*(\d{2}):(\d{2})(.*):(.*)\((.*)\).* (\d{1,})/g).exec(line);
    time.hour = line[1];
    time.minute = line[2];
    
    //set timestamp
    event.time.timestamp = moment.tz(time, 'Europe/Bucharest').utc().format();
    //set competition
    event.competition = line[3].trim();
    //set extra infos
    event.infos = line[5].trim();
    //set channel
    event.channel = line[6].trim();

    line = line[4];
    if (event.competition.toLowerCase().match(sportsRegex.fighting)) {
        event.fight = line.trim()
    } else if (event.competition.toLowerCase().match(sportsRegex.racing)) {
        event.race = line.trim()
    } else {
        event.teams = {
            home: '',
            away: ''
        }

        if (event.competition.toLowerCase().match(sportsRegex.reverseTeams)) {
            /*
                line[2] --> Home Team
                line[1] --> Away Team
            */
            if (line.match(/(.*)@(.*)/g)) {
                line = (/(.*)@(.*)/g).exec(line)
                event.teams.home = line[2].trim()
                event.teams.away = line[1].trim()
            } else {
                //Special case for Redzone or similar situations          
                event.teams.home = line.trim()
            }

            if(event.competition.toLocaleLowerCase() === "mlb") {
                event.activeFeed = 0;
            }
        } else {
            if (line.match('-')) {
                /*
                    line[1] --> Home Team
                    line[2] --> Away Team
                */

               line = (/(.*)-(.*)/g).exec(line)
               event.teams.home = line[1].trim()
               event.teams.away = line[2].trim()
            } else {
                //Special case for simulcast/konferenz          
                event.teams.home = line.trim()
            }
        }
    }

    return event;
}

module.exports = {
    generateSportsRegex,
    parseEvent
}

