import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import VueResource from 'vue-resource'

Vue.use(VueResource);
Vue.config.productionTip = false

Vue.mixin({
  data: function() {
    return {
      get serverURL() {
        if (process.env.NODE_ENV === 'development')
          return 'http://localhost:5100';
          
        return '';
      }
    }
  }
})

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
